<template>
    <div class="ship-info-container">
        <div class="search-section">
            <div class="search-item">
                <span class="search-title">船舶名称：</span>
                <Input v-model="searchForm.shipName" placeholder="请输入船舶名称" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">船舶编码：</span>
                <Input v-model="searchForm.shipid" placeholder="请输入船舶ID" style="width: 150px"></Input>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
        </div>
        <Table :loading="loading" :data="shipList" :columns="columns"></Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small"
            style="margin-top: 20px" show-total show-elevator show-sizer></Page>
    </div>
</template>

<script>
import { getShopInfoList } from '@/utils/api';
import { customPrint, timeFormatSeconds } from '@/utils/util';

export default {
    data() {
        return {
            shipList: [
            ],
            loading: false,
            columns: [
                {
                    title: "序号",
                    key: "num",
                    width: 70,
                },
                {
                    title: "船舶编码",
                    key: "shipid",
                    width: 200,
                },
                {
                    title: "船舶类型",
                    key: "shiptypeName",
                    width: 200,
                },
                {
                    title: "船舶名称",
                    key: "shipName",
                    minWidth: 150,
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    width: 150,
                },
                {
                    title: "更新时间",
                    key: "updateTime",
                    width: 200,
                }
            ],
            searchForm: {
                shipName: '',
                shipid: ''
            },
            total: 0,
            pageSize: 10,
            pageNum: 1,
        };
    },
    created() {
        this.getShipList();
    },
    methods: {
        searchHandler() {
            this.pageNum = 1;
            this.getShipList();
        },
        resetHandler() {
            this.pageSize = 10;
            this.pageNum = 1;
            this.searchForm.shipid = '';
            this.searchForm.shipName = '';
            this.getShipList();
        },
        pageChange(num) {
            this.pageNum = num;
            this.getShipList();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getShipList();
        },
        getShipList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                gasMonitorDTO: {}
            };
            if (this.searchForm.shipid) {
                params.gasMonitorDTO.shipid = this.searchForm.shipid;
            }
            if (this.searchForm.shipName) {
                params.gasMonitorDTO.shipName = this.searchForm.shipName;
            }
            getShopInfoList(params).then(res => {
                customPrint('船舶列表:' + JSON.stringify(res));
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    list.forEach((item, index) => {
                        item.num = index + 1;
                        // item.createTime = timeFormatSeconds(item.createTime);
                        // item.updateTime = timeFormatSeconds(item.updateTime);
                    })
                    this.shipList = list;
                } else { }
            })
        }
    }
};
</script>

<style lang="scss">
.ship-info-container {
    height: 100%;
    padding: 30px;
    text-align: center;
    overflow-y: auto;

    .search-section {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 20px;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }
        }

        .search-title {
            font-size: 16px;
            color: #fff;
        }
    }
}
</style>